/**
 * Write-only the password as cookie
 * node_modules/@mkitio/gatsby-theme-password-protect/src/components/PasswordProtect.js
 * src/gatsby-theme-password-protect/components/PasswordProtect.js
 * src/gatsby-theme-password-protect/components/PasswordProtect.js
 */
import Layout from "Layouts/layout";
import React, { useState } from "react";
import styled from "styled-components";
import * as utils from "../utils/utils"; 

const styles = {};

const PasswordProtect = () => {
  const [password, setPassword] = useState("");
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    utils.setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Layout>
      <Wrapper>
      <Title>Welcome</Title>
      <h4>Enter Password</h4>

      <form onSubmit={onSubmit} style={{ width: "320px" }}>
        <input style={{width: "100%", height: "48px", background: "none", fontSize: "large"}}
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <Enter
          type="submit"
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Enter
        </Enter>
      </form>
      </Wrapper>
    </Layout>
  );
};

const Enter = styled.button`
    width: 100%;
    height: 48px;
    background: none;
    border: none;
    background: var(--color-category-button);
    color: var(--color-floating-button-text);
    :hover {
      background: var(--color-floating-button-hover);
      color: var(--color-floating-button-text-hover);
    }
`

const Title = styled.h1`
  font-weight: var(--font-weight-bold);
  line-height: 1.1875;
  font-size: var(--text-xl);

  @media (max-width: ${({ theme }) => theme.device.md}) {
    line-height: 1.21875;
    font-size: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.device.sm}) {
    line-height: 1.21875;
    font-size: 2rem;
  }
`

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  * {
    margin-top: 16px;
  }
`

export default PasswordProtect;
